<template>
  <div class="mh-48">
    <div
      class="btn"
      v-if="!copied"
      v-clipboard="value"
      v-clipboard:success="clipboardSuccessHandler"
    >
      Скопировать ссылку
    </div>
    <div v-if="copied">
      <span class="font-weight-bold">Ок,</span> ссылка у вас в буфере обмена
      (Ctrl + V)
      <span class="tip ml-4" v-clipboard="value">Скопировать еще раз</span>
    </div>
  </div>
</template>

<script>
/*
  v-clipboard не работает если используется внутри v-dialog на v-btn
  Не работает даже если есть v-btn рядом (возможно даже не в диалоге дело)
  Поэтому сделал div со стилями кнопки
*/
export default {
  props: ['value'],
  data() {
    return {
      copied: false,
    }
  },
  methods: {
    clipboardSuccessHandler() {
      this.copied = true
    },
  },
}
</script>

<style scoped>
.mh-48 {
  min-height: 48px;
}
.btn {
  cursor: pointer;
  height: 36px;
  padding: 0 16px;
  background-color: #f5f5f5;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  white-space: nowrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:hover {
  background-color: #e5e5e5;
}
.tip {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  cursor: pointer;
  transition: opacity 0.2s ease;
}
.tip:hover {
  opacity: 0.4;
}
</style>
