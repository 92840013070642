<template>
  <div>
    <v-btn @click="onOpen" text small
      ><v-icon class="mr-1">mdi-lock-reset</v-icon>Сбросить пароль</v-btn
    >
    <v-dialog v-model="dialog" max-width="760">
      <div class="card">
        <div class="title">Сбросить пароль</div>
        <div v-if="!success">
          <v-checkbox
            label="Я понимаю зачем я сбрасываю пароль"
            v-model="confirm"
            hide-details
            class="ma-0 pa-0"
            color="error"
          ></v-checkbox>
          <v-btn :disabled="!confirm" color="error" @click="onResetPassword"
            >Сбросить пароль</v-btn
          >
        </div>
        <div v-if="success">
          <div>
            <span class="font-weight-bold">Успешно.</span> Теперь отправьте
            пользователю ссылку.
          </div>
          <div class="link">{{ link }}</div>
          <CopyToClipboard :value="link" />
        </div>
        <div v-if="error">
          Не удалось сбросить пароль для этого пользователя. Попробуйте еще раз.
          Или попробуйте обновить страницу и нажать на кнопку снова.
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CopyToClipboard from '@/components/buttons/CopyToClipboard'

import { resetPassword } from '@/lib/requestsAPI'
import { RESET_PASSWORD } from '@/store/const/path'

export default {
  props: ['userId'],
  data() {
    return {
      dialog: false,
      confirm: false,
      success: false,
      token: '',
      error: false,
    }
  },
  components: { CopyToClipboard },
  computed: {
    link() {
      return `${window.location.origin}/${RESET_PASSWORD}/${this.token}`
    },
  },
  methods: {
    onOpen() {
      this.dialog = true
    },
    onResetPassword() {
      resetPassword(this.userId)
        .then(res => {
          this.token = res
          this.success = true
          this.confirm = false
        })
        .catch(err => {
          this.error = true
          console.error(err)
        })
    },
  },
}
</script>

<style scoped>
.link {
  padding: 8px;
  font-family: monospace;
  border: 1px solid #888;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 8px;
}
.card {
  padding: 16px;
  background-color: white;
  min-height: 180px;
}
</style>
