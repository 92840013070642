<template>
  <div>
    <v-btn text small @click="onOpen"
      ><v-icon class="mr-1">mdi-delete</v-icon>Удалить</v-btn
    >

    <v-dialog v-model="dialog" max-width="600" @click:outside="onClose">
      <v-card>
        <v-card-title class="headline"
          >Удалить пользователя {{ userFio }}?</v-card-title
        >
        <v-card-text>
          <div>
            Тут будет описание того, что пропадет если удалить пользователя. Это
            будет зависеть от его роли. Но в общем случае пропадут все его
            ответы.
          </div>
          <v-checkbox
            v-model="confirm"
            color="error"
            value="error"
            label="Да удалить."
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="onDelete" :disabled="!confirm"
            >Удалить</v-btn
          >
          <v-btn @click="onClose">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { deleteUser } from '@/lib/requestsAPI'

import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'
import { dialogMixin } from '@/mixins/dialogMixin'

import { USERS, COMPONENT_PATHS } from '@/store/const/path'

export default {
  props: ['userFio', 'userId'],
  mixins: [dialogMixin, sendFormWithToastAndRedirect],
  methods: {
    onDelete() {
      this.sendFormWithToastAndRedirect({
        action: deleteUser,
        data: this.userId,
        successPath: COMPONENT_PATHS[USERS].path,
        successMessage: `${this.userFio} удален!`,
        errorMessage: `Не получилось удалить пользователя ${this.userId}`,
      })
    },
  },
}
</script>
