<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader :text="userFio">
        <EditButton v-if="!isRegion" label="Редактировать" />
        <DeleteUserWithDialog :userFio="userFio" :userId="userId" />
        <ResetPasswordWithDialog :userId="userId" />
      </PageHeader>

      <div class="mb-4">
        <TitleAndDataLine label="Логин" :value="user.login" />
        <TitleAndDataLine label="Фио" :value="userFio" />
        <TitleAndDataLine label="Email" :value="user.email" />
        <TitleAndDataLine label="Телефон" :value="user.phone" />
        <TitleAndDataLine label="Роль" :value="role" />
        <TitleAndDataProfiles :profiles="profiles" />
      </div>

      <div v-if="isNMIC">
        <TitleAndDataLine
          label="Ссылка этого НМИЦ для приглашения регионов"
          :value="link"
          class="mb-2"
        />
        <CopyToClipboard :value="link" />
      </div>
    </div>
    <div v-else>Нет данных для этого пользователя</div>
  </DisplayLoading>
</template>

<script>
import EditButton from '@/components/buttons/EditButton.vue'
import DeleteUserWithDialog from '@/components/buttons/DeleteUserWithDialog.vue'
import ResetPasswordWithDialog from '@/components/buttons/ResetPasswordWithDialog.vue'
import CopyToClipboard from '@/components/buttons/CopyToClipboard.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import TitleAndDataLine from '@/components/common/TitleAndDataLine.vue'
import TitleAndDataProfiles from '@/components/common/TitleAndDataProfiles.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { loadPageData } from '@/mixins/loadPageData'
import { routeParams } from '@/mixins/routeParams'

import { API_USERS } from '@/store/const/apiPaths'
import {
  USER_ROLES,
  USER_REGION_ID,
  USER_NMIC_ID,
} from '@/store/const/userRoles'
import { REGISTRATION } from '@/store/const/path'

export default {
  data() {
    return {
      loadingMessage: 'Загружаю данные пользователя',
    }
  },
  components: {
    EditButton,
    DeleteUserWithDialog,
    PageHeader,
    TitleAndDataLine,
    DisplayLoading,
    TitleAndDataProfiles,
    ResetPasswordWithDialog,
    CopyToClipboard,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_USERS}/${this.userId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для пользователя: ${this.userId}`
    },
    user() {
      return this.pageData
    },
    isRegion() {
      return this.user.role_id === USER_REGION_ID
    },
    isNMIC() {
      return this.user.role_id === USER_NMIC_ID
    },
    role() {
      return USER_ROLES[this.user.role_id].display
    },
    userFio() {
      return this.user.fio
    },
    profiles() {
      return this.user.profiles
    },
    link() {
      return `${window.location.origin}/${REGISTRATION}/${this.user.token}`
    },
  },
}
</script>
